import Post from './post';

export const RECENT_POSTS_PAGE_SIZE = 7;

export interface RecentPostsResponse {
  posts: Post[];
  from: number;
  to: number;
  totalCount: number;
}
