import React, { useEffect, useState } from 'react';
import type { GetStaticProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import classNames from 'classnames/bind';
import { getTopPostsApi } from '../apis/topPostsApi';
import { getPhase } from '../types/environment';
import Post from '../types/post';
import { getPagedRecentPosts } from '../singletons/posts';
import { getTopTags } from '../singletons/tags';
import { LocaleCode } from '../types/locale';
import { getFeaturedPosts } from '../singletons/featuredPosts';
import { RecentPostsResponse, RECENT_POSTS_PAGE_SIZE } from '../types/recentPost';
import Log from '../utils/log';
import FeaturedPosts from '../components/main/FeaturedPosts';
import RecentPosts from '../components/main/RecentPosts';
import Tags from '../components/common/Tags';
import TopPosts from '../components/main/TopPosts';
import MeetClova from '../components/main/MeetClova';
import Banner from '../components/main/Banner';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

// constants
const TOP_POSTS_MAX_SIZE = 6;
const TOP_POSTS_MIN_SIZE = 3;

export type MainPageProps = {
  featuredPosts: Post[];
  initialRecentPostsResponse: RecentPostsResponse;
  topTags: string[];
};

const MainPage: NextPage<MainPageProps> = ({ featuredPosts, initialRecentPostsResponse, topTags }) => {
  const { locale } = useRouter();
  const { t } = useTranslation('main');
  const isExpanded = getPhase() === 'EXPANDED';

  // top posts
  const [topPosts, setTopPosts] = useState<Post[]>([]);

  useEffect(() => {
    async function fetchTopPosts() {
      try {
        const { posts } = await getTopPostsApi(locale as LocaleCode, TOP_POSTS_MAX_SIZE);
        const sliced = posts.length < TOP_POSTS_MAX_SIZE ? posts.slice(0, TOP_POSTS_MIN_SIZE) : posts; // 6개가 채워지지 못하는 경우 3개까지만 출력
        setTopPosts(sliced);
      } catch (e) {
        Log.print({ level: 'error', message: 'failed to fetch top posts' }, { exception: e });
      }
    }

    fetchTopPosts();
  }, [locale]);

  return (
    <>
      <div className={cx('post_tech')}>
        <div className={cx('tech_inner')}>
          <div className={cx('tech_wrap')}>
            <h2 className={'blind'}>Featured Posts</h2>
            <FeaturedPosts featuredPosts={featuredPosts} />
          </div>
          <div className={cx('banner_wrap')}>
            <Banner />
          </div>
        </div>
      </div>
      <div className={cx('post_recent')}>
        <div className={'content_wrap'}>
          <div className={cx('recent_inner', { '-expand': isExpanded })}>
            <div className={cx('recent_wrap')}>
              <h2 className={'main_category'}>Recent Posts</h2>
              <RecentPosts initialResponse={initialRecentPostsResponse} />
            </div>
            {isExpanded && (
              <div className={cx('tag_wrap')}>
                <h2 className={'main_category'}>Top Tags</h2>
                <Tags tags={topTags} nClicksCode="main.toptags" />
              </div>
            )}
          </div>
        </div>
      </div>
      {topPosts.length > 0 && (
        <div className={cx('post_top', { '-expand': isExpanded })}>
          <div className={'content_wrap'}>
            <h2 className={'main_category'}>Top Posts</h2>
            <TopPosts topPosts={topPosts} />
          </div>
        </div>
      )}
      <div className={cx('meet_clova')}>
        <div className={'content_wrap'}>
          <div className={'category_wrap'}>
            <h2 className={'main_category'}>Meet CLOVA</h2>
            <p className={'main_category_info'}>{t('meet_clova_description')}</p>
          </div>
          <MeetClova />
        </div>
      </div>
      {/*{isExpanded && <AiNow />}*/}
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const localeCode = (locale ?? 'ko') as LocaleCode;

  // featured posts
  const featuredPosts = await getFeaturedPosts(localeCode, 'LATEST'); // LATEST mode 적용 중 (최신글 n개 표시)

  // recent posts (첫번째 페이지는 미리 server-side render)
  let initialRecentPostsResponse: RecentPostsResponse = { posts: [], from: 0, to: 0, totalCount: 0 };
  try {
    initialRecentPostsResponse = await getPagedRecentPosts(localeCode, 0, RECENT_POSTS_PAGE_SIZE, true);
  } catch (e) {
    const message = `failed to get the first page of recent posts (localeCode: ${localeCode}, from: ${0}, size: ${RECENT_POSTS_PAGE_SIZE}, includingScheduled: ${true})`;
    Log.print({ level: 'error', message }, { exception: e });
  }

  // top tags
  const topTags = await getTopTags(localeCode);

  return {
    props: {
      featuredPosts,
      initialRecentPostsResponse,
      topTags,
      ...(await serverSideTranslations(locale ?? 'ko', ['common', 'main'])),
    },
    revalidate: 3600, // hourly
  };
};

export default MainPage;
