import React from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useMobileMediaQuery, usePCWideMediaQuery, useTabletPCMediaQuery } from '../../hooks/useResponsiveMediaQuery';
import nClicks from '../../utils/nClicks';
import styles from './Banner.module.scss';

const cx = classNames.bind(styles);

function Banner(): JSX.Element {
  const { locale } = useRouter();
  const { t } = useTranslation('main');
  const isEnglish = locale === 'en';
  const isPCWide = usePCWideMediaQuery();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletPCMediaQuery();
  const recruitmentUrl = 'https://clova.ai/hyperclova';

  return (
    <Link href={recruitmentUrl}>
      <a
        className={cx('banner_link', { [`-english`]: isEnglish })}
        target="_blank"
        onClick={(e) => nClicks(e, 'main.mainbanner')}
      >
        <div className={'banner_wrap'}>
          <p className={cx('banner_detail')}>
            <span className={cx('logo_hyperclovax')}>
              <span className="blind">HyperCLOVA X</span>
            </span>
            <span className={cx('banner_detail_text')}>
              {isMobile && t('banner_slogan_mobile')}
              {isTablet && t('banner_slogan_tablet')}
              {isPCWide && t('banner_slogan_pc_wide')}
            </span>
          </p>
          {/* 현재 sub link는 visibility: hidden이 적용되어 있음 (추후 링크 제공받으면 다시 노출) */}
          <p className={cx('banner_sub_link_wrap')}>
            <span className={cx('banner_sub_link')}>{t('banner_research_specialist')}</span>
            <span className={cx('banner_sub_link')}>{t('banner_ai_sw_engineer')}</span>
          </p>
        </div>
      </a>
    </Link>
  );
}

export default Banner;
