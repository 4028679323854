import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames/bind';
import { CLOVA_SERVICES } from '../../types/clovaService';
import { usePCWideMediaQuery, useTabletPCMediaQuery } from '../../hooks/useResponsiveMediaQuery';
import { chunkArray } from '../../utils/array';
import nClicks from '../../utils/nClicks';
import Carousel from '../common/Carousel';
import styles from './MeetClova.module.scss';

const cx = classNames.bind(styles);

function requestNClicks(e: React.MouseEvent) {
  nClicks(e, 'main.meetclova');
}

// carousel
const chunkedClovaServices = {
  1: chunkArray(CLOVA_SERVICES, 1), // for PC, Mobile:  [ [service1], [service2], [service3], [service4], ... ]
  2: chunkArray(CLOVA_SERVICES, 2), // for Tablet:      [ [service1, service2], [service3, service4], ... ]
};

const MeetClovaItemWrapper: React.ComponentType<{ className: string; isPcWide: boolean; isTabletPC: boolean }> = ({
  children,
  className,
  isPcWide,
  isTabletPC,
}) => {
  // - PC: <ul>에 4개의 post 단순 나열
  if (isPcWide) {
    return <ul className={className}>{children}</ul>;
  }

  // - Tablet: <Carousel>에 페이지 당 2개의 service가 보이도록 나열
  // - Mobile: <Carousel>에 페이지 당 1개의 service가 보이도록 나열
  const id = isTabletPC ? 'meet-clova-tablet' : 'meet-clova-mobile';
  return (
    <Carousel id={id} key={id} className={className}>
      {children}
    </Carousel>
  );
};

function MeetClova(): JSX.Element {
  // i18n
  const { t } = useTranslation('main');

  // 반응형
  const isPcWide = usePCWideMediaQuery();
  const isTabletPC = useTabletPCMediaQuery();
  const services = isTabletPC ? chunkedClovaServices[2] : chunkedClovaServices[1];

  return (
    <MeetClovaItemWrapper className={cx('meet_clova_list')} isPcWide={isPcWide} isTabletPC={isTabletPC}>
      {services.map((chunk) => {
        // chunk: clova service를 1~2개 담고있는 list
        const key = chunk?.length === 1 ? chunk[0].id : chunk.map((post) => post.id ?? '').join('-');
        return (
          <li key={key} className={cx('meet_clova_item_wrap')}>
            {chunk.map((service) => (
              <div className={cx('meet_clova_item')} key={service.id}>
                <Link href={t(service.urlI18nKey)}>
                  <a className={cx('meet_clova_link')} target="_blank" onClick={requestNClicks}>
                    <div className={cx('meet_clova_img_wrap')}>
                      <Image src={service.image} alt={'meetImage'} quality={95} layout="responsive" />
                    </div>
                    <div className={cx('meet_clova_text_wrap')}>
                      <strong className={cx('meet_clova_title')}>{service.title}</strong>
                      <p className={cx('meet_clova_info')}>{t(service.descriptionI18nKey)}</p>
                    </div>
                  </a>
                </Link>
              </div>
            ))}
          </li>
        );
      })}
    </MeetClovaItemWrapper>
  );
}

export default MeetClova;
