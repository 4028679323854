import axios from 'axios';
import { LocaleCode } from '../types/locale';
import Post from '../types/post';
import { restoreDateProperties } from '../utils/date';

export async function getTopPostsApi(localeCode: LocaleCode, size: number) {
  const { data } = await axios.get<Post[]>(`/api/top-posts/${localeCode}?size=${size}`);
  const dateRestoredPosts = data.map((post) => restoreDateProperties(post, ['createdAt']));
  return { posts: dateRestoredPosts };
}
