import React from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';
import PostInfo from '../post/PostInfo';
import Post from '../../types/post';
import { usePCWideMediaQuery, useTabletPCMediaQuery } from '../../hooks/useResponsiveMediaQuery';
import { getPostPageUrl } from '../../utils/url';
import nClicks from '../../utils/nClicks';
import styles from './TopPosts.module.scss';

const cx = classNames.bind(styles);

function requestNClicks(e: React.MouseEvent) {
  nClicks(e, 'main.topposts');
}

export type TopPostsProps = {
  topPosts: Post[];
};

function TopPosts({ topPosts }: TopPostsProps): JSX.Element {
  const isPcWide = usePCWideMediaQuery();
  const isTabletPC = useTabletPCMediaQuery();
  const isExpanded = (topPosts.length >= 4 && isTabletPC) || isPcWide;

  return (
    <ol className={cx('top_list', { '-expand': isExpanded })}>
      {topPosts.map((post, index) => {
        const url = getPostPageUrl(post.year, post.month, post.slug);
        const postIndex = `${index + 1}`.padStart(2, '0');

        return (
          <li className={cx('top_item')} key={post.id}>
            <Link href={url}>
              <a className={cx('top_link')} onClick={requestNClicks}>
                <span className={cx('top_number')}>{postIndex}</span>
                <div className={cx('top_text')}>
                  <strong className={cx('top_title')}>{post.title}</strong>
                  <p className={cx('top_detail')}>{post.excerpt}</p>
                  <div className={cx('top_info')}>
                    <PostInfo createdAt={post.createdAt} authors={post.authors} isScheduled={post.isScheduled} />
                  </div>
                </div>
              </a>
            </Link>
          </li>
        );
      })}
    </ol>
  );
}

export default TopPosts;
