import React, { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames/bind';
import PostInfo from '../post/PostInfo';
import PostThumbnail from '../post/PostThumbnail';
import { RecentPostsResponse, RECENT_POSTS_PAGE_SIZE } from '../../types/recentPost';
import { LocaleCode } from '../../types/locale';
import { getPostPageUrl } from '../../utils/url';
import { getPostContentSrc } from '../../utils/postInfo';
import nClicks from '../../utils/nClicks';
import Log from '../../utils/log';
import { getRecentPostsApi } from '../../apis/recentPostsApi';
import styles from './RecentPosts.module.scss';

const cx = classNames.bind(styles);

function requestNClicks(e: React.MouseEvent) {
  nClicks(e, 'main.recentposts');
}

// 공개글은 <Link>, <a>로, 예약글은 <div>로 wrapping
const RecentPostWrapper: React.ComponentType<{ isScheduled: boolean; url: string }> = ({
  children,
  isScheduled,
  url,
}) => {
  if (isScheduled) {
    return (
      <div className={cx('recent_link')} onClick={requestNClicks}>
        {children}
      </div>
    );
  }

  return (
    <Link href={url}>
      <a className={cx('recent_link')} onClick={requestNClicks}>
        {children}
      </a>
    </Link>
  );
};

export type RecentPostsProps = {
  initialResponse: RecentPostsResponse;
};

function RecentPosts({ initialResponse }: RecentPostsProps): JSX.Element {
  const { locale } = useRouter();
  const { t } = useTranslation('main');
  const [response, setResponse] = useState(initialResponse); // 'response.posts'는 포스트 목록의 한 페이지만 가지고 있으므로,
  const [posts, setPosts] = useState(initialResponse.posts); //  별도의 'posts' state에서 누적된 포스트 목록을 관리 (= 화면에 표시할 포스트 목록)
  const [isWaitingApi, setIsWaitingApi] = useState(false);

  const isPostRemaining = response.to < response.totalCount - 1;

  const handleMoreButtonClick = async (from: number) => {
    if (!isPostRemaining || isWaitingApi) {
      return;
    }

    try {
      // recent posts API 요청 후 post 목록 갱신
      setIsWaitingApi(true);
      const recentPostsResponse = await getRecentPostsApi(locale as LocaleCode, from, RECENT_POSTS_PAGE_SIZE);
      setResponse(recentPostsResponse);
      setPosts((posts) => [...posts, ...recentPostsResponse.posts]);
    } catch (e) {
      Log.print({ level: 'error', message: 'an error occured while fetching recent posts' }, { exception: e });
    } finally {
      setIsWaitingApi(false);
    }
  };

  return (
    <>
      <ul className={cx('recent_list')}>
        {posts.map((post) => {
          const postPageUrl = getPostPageUrl(post.year, post.month, post.slug);
          const thumbnailUrl = post.heroImage ? getPostContentSrc(post.heroImage) : undefined;
          const videoUrl = post.heroVideo ? getPostContentSrc(post.heroVideo) : undefined;

          return (
            <li className={cx('recent_item')} key={post.id}>
              <RecentPostWrapper isScheduled={post.isScheduled} url={postPageUrl}>
                <PostThumbnail
                  className={cx('recent_image')}
                  isScheduled={post.isScheduled}
                  thumbUrl={thumbnailUrl}
                  videoUrl={videoUrl}
                  interviewMetadata={post.interview}
                />
                <div className={cx('recent_text')}>
                  <strong className={cx('recent_title')}>{post.title}</strong>
                  <p className={cx('recent_detail')}>{post.excerpt}</p>
                  <div className={cx('recent_info')}>
                    <PostInfo createdAt={post.createdAt} authors={post.authors} isScheduled={post.isScheduled} />
                  </div>
                </div>
              </RecentPostWrapper>
            </li>
          );
        })}
      </ul>
      {isPostRemaining && (
        <button
          type={'button'}
          className={cx('more')}
          disabled={isWaitingApi}
          onClick={() => handleMoreButtonClick(response.to + 1)}
        >
          {t('recent_posts_more_button')}
        </button>
      )}
    </>
  );
}

export default RecentPosts;
