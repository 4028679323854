import axios from 'axios';
import { LocaleCode } from '../types/locale';
import { RecentPostsResponse } from '../types/recentPost';
import { restoreDateProperties } from '../utils/date';

export async function getRecentPostsApi(localeCode: LocaleCode, from: number, size: number) {
  const { data } = await axios.get<RecentPostsResponse>(`/api/recent-posts/${localeCode}?from=${from}&size=${size}`);
  const dateRestoredPosts = data.posts.map((post) => restoreDateProperties(post, ['createdAt']));
  return { ...data, posts: dateRestoredPosts };
}
