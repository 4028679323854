import { StaticImageData } from 'next/image';
import clovaNoteImage from '../../public/contents/meet_clova/clova_note.png';
import clovaDubbingImage from '../../public/contents/meet_clova/clova_dubbing.png';
import clovaSpeechImage from '../../public/contents/meet_clova/clova_speech.png';
import clovaVoiceImage from '../../public/contents/meet_clova/clova_voice.png';
import clovaOcrImage from '../../public/contents/meet_clova/clova_ocr.png';
import clovaAiccImage from '../../public/contents/meet_clova/clova_ai.png';

type ClovaService = {
  id: string;
  title: string;
  image: StaticImageData;
  descriptionI18nKey: string;
  urlI18nKey: string;
};

export const CLOVA_SERVICES: ClovaService[] = [
  {
    id: 'clova-note',
    title: 'CLOVA Note',
    image: clovaNoteImage,
    descriptionI18nKey: 'meet_clova_clova_note_description',
    urlI18nKey: 'meet_clova_clova_note_url',
  },
  {
    id: 'clova-dubbing',
    title: 'CLOVA Dubbing',
    image: clovaDubbingImage,
    descriptionI18nKey: 'meet_clova_clova_dubbing_description',
    urlI18nKey: 'meet_clova_clova_dubbing_url',
  },
  {
    id: 'clova-speech',
    title: 'CLOVA Speech',
    image: clovaSpeechImage,
    descriptionI18nKey: 'meet_clova_clova_speech_description',
    urlI18nKey: 'meet_clova_clova_speech_url',
  },
  {
    id: 'clova-voice',
    title: 'CLOVA Voice',
    image: clovaVoiceImage,
    descriptionI18nKey: 'meet_clova_clova_voice_description',
    urlI18nKey: 'meet_clova_clova_voice_url',
  },
  {
    id: 'clova-ocr',
    title: 'CLOVA OCR',
    image: clovaOcrImage,
    descriptionI18nKey: 'meet_clova_clova_ocr_description',
    urlI18nKey: 'meet_clova_clova_ocr_url',
  },
  {
    id: 'clova-aicc',
    title: 'CLOVA AI for Contact Center',
    image: clovaAiccImage,
    descriptionI18nKey: 'meet_clova_clova_aicc_description',
    urlI18nKey: 'meet_clova_clova_aicc_url',
  },
];

export default ClovaService;
