import React from 'react';
import Link from 'next/link';
import classNames from 'classnames/bind';
import Post from '../../types/post';
import { getPostContentSrc } from '../../utils/postInfo';
import { getPostPageUrl } from '../../utils/url';
import nClicks from '../../utils/nClicks';
import PostInfo from '../post/PostInfo';
import PostThumbnail from '../post/PostThumbnail';
import Carousel from '../common/Carousel';
import styles from './FeaturedPosts.module.scss';
import '@egjs/react-flicking/dist/flicking.css';

const cx = classNames.bind(styles);

function nClicksRequest(e: React.MouseEvent) {
  nClicks(e, 'main.featured');
}

// 공개글은 <Link>, <a>로, 예약글은 <div>로 wrapping
const FeaturedPostWrapper: React.ComponentType<{ isScheduled: boolean; url: string }> = ({
  children,
  isScheduled,
  url,
}) => {
  if (isScheduled) {
    return (
      <div className={cx('featured_link')} onClick={nClicksRequest}>
        {children}
      </div>
    );
  }

  return (
    <Link href={url}>
      <a className={cx('featured_link')} onClick={nClicksRequest}>
        {children}
      </a>
    </Link>
  );
};

export type FeaturedPostsProps = {
  featuredPosts: Post[];
};

function FeaturedPosts({ featuredPosts }: FeaturedPostsProps): JSX.Element {
  return (
    <Carousel id="featured-posts" className={cx('featured_posts')}>
      {featuredPosts.map((post) => {
        const postPageUrl = getPostPageUrl(post.year, post.month, post.slug);
        const thumbnailUrl = post.heroImage ? getPostContentSrc(post.heroImage) : undefined;
        const videoUrl = post.heroVideo ? getPostContentSrc(post.heroVideo) : undefined;

        return (
          <div className={cx('featured_item', 'panel')} key={post.id}>
            <FeaturedPostWrapper isScheduled={post.isScheduled} url={postPageUrl}>
              <div className={cx('featured_link')}>
                <PostThumbnail
                  className={cx('featured_image')}
                  isScheduled={post.isScheduled}
                  thumbUrl={thumbnailUrl}
                  videoUrl={videoUrl}
                />
                <div className={'content_wrap'}>
                  <div className={cx('featured_text')}>
                    <em className={'main_category'}>{post.category}</em>
                    <strong className={cx('featured_title')}>{post.title}</strong>
                    <p className={cx('featured_detail')}>{post.excerpt}</p>
                    <div className={cx('featured_info')}>
                      <PostInfo
                        className={cx('featured_post_info')}
                        createdAt={post.createdAt}
                        authors={post.authors}
                        isScheduled={post.isScheduled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FeaturedPostWrapper>
          </div>
        );
      })}
    </Carousel>
  );
}

export default FeaturedPosts;
